const theme = {
  colors: {
    primary: '#EDEBE7',
    secondary: '#DFDDD7',
    greyLight: '#DAD6CF',
    greyDark: '#7A7A7A',
    black: '#1A171B',
    red: '#E2001A',
    blue: '#115C96',
    inputText: 'rgba(00,00,00, .2)',
    shadow: 'rgba(26, 23, 27, .15)',
  },
  screens: {
    xs: '@media(max-width: 576px)',
    s: '@media(min-width: 576px)',
    m: '@media(min-width: 768px)',
    l: '@media(min-width: 992px)',
    xl: '@media(min-width: 1200px)',
  },
}

export default theme
