import { createGlobalStyle, ThemeProps } from 'styled-components'

export interface GlobalProps {
  theme: ThemeProps<unknown>
}

export default createGlobalStyle<GlobalProps>`
    *{
        margin: 0;
        box-sizing: border-box;
    }

    #root{
        margin: 0;
        padding: 0;
    }

    body{
        font-family: 'Franklin Gothic FontB';
        font-size: 1rem;
        font-weight: 400;
		color:black;
        background-color: #EDEBE7;
		margin: 0;
		padding: 0;
    }

    img{
        max-width: 100%;
    }

    /* Global CSS classes */
    .border{
        border-style: solid !important;
    }
    .border-10{
        border-width: 10px !important;
    }
    .border-75{
        border-width: 75px !important;
    }
    .border-blue{
        border-color: #115C96 !important;
    }
    .bg-blue{
        background-color: #115C96 !important;
    }
    .border-grey-light{
        border-color: #DAD6CF !important;
    }
    .border-grey-dark{
        border-color: #7A7A7A !important;
    }
    .mb-0{
        margin-bottom: 0 !important;
    }
    .mt-1{
        margin-top: 11px !important;
    }
    .mb-1{
        margin-bottom: 11px !important;
    }

    .iframe{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* HOME PAGE */
    .puzzle-selection-wrapper{
        padding: 13px;
        background: #D9D9D9;
        width: min(215px,100%);
        margin: 0 auto;
        margin-top: 1rem;
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img{
            border-width: 4px;
        }
        button{
            border: none;
            background: transparent;
            cursor: pointer;
        }
        @media screen and (min-width: 768px){
            width: min(320px,100%);
            padding: 25px;
            img{
                border-width: 10px;
            } 
        }
    }
    
    #home-page{
        .home-page-wrapper{
            align-items: flex-start;
            margin-top: 50px;
            @media (min-width: 991px){
                align-items: center;
                margin-top: 0;
            }   
        }
        h1{
            font-family: 'Franklin Gothic FontD';
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 0.5rem;
            line-height: 1.1;
            text-align: center;
            color: black;
            @media(min-width: 768px) {
                font-size: 28px;
                line-height: 1.2;
            }
            @media(min-width: 992px) {
                font-size: 38px;
                line-height: 1.2;
            }
        }
        p{
            letter-spacing: 0.1px;
            margin-bottom: 10px;
        }
        .start-btn {
            color: white;
            width: max-content;
            background-color: rgb(226, 0, 26);
            border: 1px solid rgb(226, 0, 26);
            border-radius: 10px;
            padding: 10px 32px;
            font-size: 1.35rem;
            font-family: "Franklin Gothic FontB";
            font-weight: 700;
            letter-spacing: .2px;
            margin: 1.5rem auto 0px;
            cursor: pointer;
            text-align: center;
            text-decoration: none;
            transition: all 0.25s ease 0s;
        }
    }
    
    #form-page{
        h1{
            margin-bottom: 25px;
        }
        p {
            max-width: 340px;
        }
        @media (min-width: 991px){
            p {
                max-width: 615px;
            }
        }
    }
    
    .not_found-page,
    #play_again-page{
        h2{
            margin-top: 1rem !important;
        }
        .img-wrapper{
            margin: 0 auto;
        }
        .not_found-btn,
        .play_again-btn{
            max-width: 293px;
            width: 100%;
            margin: 0 auto 2rem;
        }
        p{
            margin-bottom: 50px;
            > a{
            display: block;
        }
        }
        @media (min-width: 991px){
            p > a{
                display: inline-block;
                margin-left: 3px;
            }
            .not_found-btn,
            .play_again-btn{
                margin-top: 0;
            }
        }
    }

    .final_image_wrapper{
        width: 230px;
        margin: 0 auto 20px;
        > h2 {
            margin: 0 0 1rem !important;
        }
        >div{
            width: 111px;
            height: 111px;
            margin: 0 auto;
            border: 5px solid #115C96;
        }
        @media (min-width: 991px){
            >div{
                width: 185px;
                height: 185px;
            }
        }
    }

    /* GAME CSS */
    .board-bg {
        background: url('images/puzzle.jpg');
        background-repeat: no-repeat;
        background-size: contain;
    }
    .board {
        display: grid;
        grid-template-columns: auto auto auto;
        margin: auto;
        padding: 0;
    }
    .board .clks {
        font-size: 0.1px;
        transition: transform .15s;
        color: transparent !important;
    }
    .board > #p11 {
        background-size: contain !important;
    }
    .board > #p12 {
        background-size: contain !important;
    }
    .board > #p13 {
        background-size: contain !important;
    }
    .board > #p21 {
        background-size: contain !important;
    }
    .board > #p22 {
        background-size: contain !important;
    }
    .board > #p23 {
        background-size: contain !important;
    }
    .board > #p31 {
        background-size: contain !important;
    }
    .board > #p32 {
        background-size: contain !important;
    }
    .board > #p33 {
        background-size: contain !important;
    }
    .tile {
        position: absolute;
        list-style: none;
        background: transparent;
        display: grid;
        place-items: center;
        font-size: 20px;
    }
    button {
        display: block;
    }
`
