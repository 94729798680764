import { useEffect } from 'react'
import TagManager, { TagManagerArgs } from 'react-gtm-module'

const useGTMTracker = () => {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID ?? 'GTM-P9SVWSS',
  }
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])
}

export default useGTMTracker
