import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'

const useGATracker = (): void => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING ?? 'UA-245594620-1')
    setInitialized(true)
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      })
    }
  }, [initialized, location])
}

export default useGATracker
