import { useEffect } from 'react'

import { useRouter } from '../lib/hooks/useRouter'

const onDefault = () => {
  document.title = 'Baumit'
  document.body.id = ''
}
const onHome = () => {
  document.title = 'Baumit'
  document.body.id = 'home-page'
}
const onForm = () => {
  document.title = 'Baumit'
  document.body.id = 'form-page'
}
const onGame = () => {
  document.title = 'Baumit'
  document.body.id = 'game-page'
}
const onPlayAgain = () => {
  document.title = 'Baumit'
  document.body.id = 'play_again-page'
}

const callbacks: any = {
  '/': [onHome],
  '/igra': [onGame],
  '/nagradna-igra': [onForm],
  '/igraj-znova': [onPlayAgain],
}

export const addPageIdentification = (_case: string, fn: () => void) => {
  callbacks[_case] = callbacks[_case] || []
  callbacks[_case].push(fn)
}

export const usePagesIdentification = () => {
  const { location } = useRouter()

  const customSwitch = (value: string) => {
    if (callbacks[value]) {
      callbacks[value].forEach((fn: () => void) => {
        fn()
      })
    } else {
      onDefault()
    }
  }

  useEffect(() => {
    if (location.pathname) customSwitch(location.pathname)
  }, [location.pathname])
}
