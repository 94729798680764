import LoadingProvider from 'components/providers/LoadingProvider/LoadingProvider'
import SnackbarProvider from 'components/providers/SnackbarProvider/SnackbarProvider'
import { observer } from 'mobx-react'
import { FC } from 'react'
import useClearErrors from 'utils/useClearErrors'
import useClearSnackbars from 'utils/useClearSnackbars'
import useGATracker from 'utils/useGATracker'
import useGTMTracker from 'utils/useGTMTracker'
import { usePagesIdentification } from 'utils/usePagesIdentification'

import Routes from './routes/Routes'

const App: FC = () => {
  useClearErrors()
  useClearSnackbars()
  usePagesIdentification()
  useGATracker()
  useGTMTracker()

  return (
    <LoadingProvider>
      <SnackbarProvider>
        <Routes />
      </SnackbarProvider>
    </LoadingProvider>
  )
}

export default observer(App)
