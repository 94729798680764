import LoadingProvider from 'components/providers/LoadingProvider/LoadingProvider'
import { observer } from 'mobx-react'
import { FC, lazy, Suspense } from 'react'
import { Route, RouteProps, Routes as Switch } from 'react-router-dom'

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED,
}

type AppRoute = {
  type?: RouteType
} & RouteProps

/* Public routes */
const Home = lazy(() => import('pages/Home/Home'))
const Game = lazy(() => import('pages/Game/Game'))
const PlayAgain = lazy(() => import('pages/PlayAgain/PlayAgain'))

/* Error routes */
const Page404 = lazy(() => import('pages/Page404/Page404'))

export const AppRoutes: AppRoute[] = [
  // Public Routes
  {
    type: RouteType.PUBLIC,
    path: '/',
    children: <Home />,
  },
  {
    type: RouteType.PUBLIC,
    path: '/igra',
    children: <Game />,
  },
  {
    type: RouteType.PUBLIC,
    path: '/igraj-znova',
    children: <PlayAgain />,
  },
  // 404 not found
  {
    type: RouteType.PUBLIC,
    path: '*',
    children: <Page404 />,
  },
]

const Routes: FC = () => {
  return (
    <Suspense fallback={<LoadingProvider loading={true} />}>
      <Switch>
        {AppRoutes.map((r) => (
          <Route key={`${r.path}`} path={`/${r.path}`} element={r.children} />
        ))}
        <Route path="*" element={<Page404 />} />
      </Switch>
    </Suspense>
  )
}

export default observer(Routes)
